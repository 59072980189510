<template>
  <div
    ref="wrapperRef"
    class="h-full flex items-center"
    :class="showMobileForm ? brandClasses.mobileWrapper : ''"
    @keydown.esc="close"
  >
    <!-- Mobile search icon -->
    <icon-search
      v-if="!dialog"
      v-model="showMobileForm"
      type="button"
      :aria-expanded="showMobileForm"
      aria-controls="vf-search-form"
      class="mx-2 cursor-pointer lg:hidden"
      :size="mobileNavIconSize"
      @click="toggleSearch"
    >
      <span class="sr-only">{{ $t.searchIconLabel }}</span>
    </icon-search>

    <!-- Backdrop -->
    <div
      v-if="!dialog"
      class="fixed-0 h-full bg-black/75"
      :class="{ '<lg:hidden': !showMobileForm, 'lg:hidden': !showSuggestions }"
      :style="{ top: `${header.height.full / 16}rem` }"
      @click.self="close"
    />
    <!-- Search form -->
    <div
      id="vf-search-form"
            :class="{
        '<lg:absolute-0 <lg:mt-14': showMobileForm && !dialog,
        '<lg:hidden': !showMobileForm && !dialog,
        'w-full': dialog,
      }"
    >
      <form
        ref="formRef"
        role="search"
        :class="[
          brandClasses.form,
          transparent ? 'b-white' : 'b-grey-30',
          { '<lg:absolute <lg:inset-x-0 <lg:px-4 <lg:py-2': !dialog },
        ]"
        @submit.prevent="submit"
      >
        <div class="w-full flex">
          <vf-input
            class="h-10 grow rounded-r-0"
            :class="[brandClasses.input, transparent ? brandClasses.inputTransparent : '']"
          >
            <span id="vf-search-form-input" class="sr-only">
              {{ $t.searchLabel }}
            </span>
            <template #input>
              <base-input
                ref="inputRef"
                v-model.trim="searchValue"
                type="text"
                :placeholder="$t.searchPlaceholder"
                aria-labelledby="vf-search-form-input"
                class="h-full focus-visible:outline-none"
                @click="handleOpenSuggestions"
                @keyup.tab="handleOpenSuggestions"
              />
            </template>
            <template #end>
              <base-button v-if="searchValue" :aria-label="$t.searchClearLabel" @click="clear(true)">
                <vf-icon name="clear" size="md" />
              </base-button>
              <template v-else-if="showImageSearch && $feature.showShopSimilarOnSearch">
                <vf-search-image-tooltip class="<lg:hidden" @click="initSyte" />
                <base-button
                  v-if="syteReady"
                  class="--syte-start-camera-upload lg:hidden"
                  :aria-label="$t.searchImageLabel"
                  data-camera-button-placement="search-bar"
                >
                  <vf-icon name="objective" size="md" />
                </base-button>
              </template>
            </template>
          </vf-input>
          <base-button
            type="submit"
            :aria-label="$t.searchButtonLabel"
            class="p-1"
            :class="[brandClasses.button, transparent ? brandClasses.buttonTransparent : '']"
            style="margin-left: -1px"
          >
            <vf-icon name="search" :size="iconSize" />
          </base-button>
        </div>
        <!-- Search suggestion box -->
        <transition
          enter-from-class="op-0"
          enter-active-class="transform ease-out"
          leave-active-class="transform ease-in"
          leave-to-class="op-0"
        >
          <div
            v-if="dialog || showSuggestions"
            ref="suggestionsRef"
            class="w-full bg-white c-grey-10 duration"
            :class="[brandClasses.suggestions.wrapper, { 'lg:mt-2 lg:w-208': !dialog }]"
            :style="dialog ? {} : {
              'min-height': '20.5rem',
              'position': strategy,
              'top': `${y / 16 || 0}rem`,
              'left': `${x / 16 || 0}rem`,
            }"
          >
            <section
              :aria-label="$t.searchResults"
              class="f-col gap-6 "
              :class="[brandClasses.suggestions.results, dialog ? 'lg:grid lg:cols-2' : 'md:flex-row md:px-6 md:py-8 lg:px-8']"
            >
              <div
                v-if="showSuggestions"
                class="space-y-6 "
                :class="{ 'col-span-3 <lg:-mx-4': dialog }"
              >
                <h2 class="mb-6 <lg:px-4 " :class="brandClasses.suggestions.title">
                  {{ $t.featuredResults }}
                </h2>
                <ul class="w-full flex gap-x-4 lg:grid lg:cols-2 lg:w-134 lg:gap-x-6 lg:gap-y-8 <lg:overflow-x-auto <lg:px-4">
                  <li
                    v-for="{ id, name, images, currency, price, url } in suggestions?.products"
                    :key="id"
                    class="relative w-22 shrink-0 gap-2 lg:w-a lg:flex"
                  >
                    <base-picture
                      v-if="images"
                      width="88"
                      height="88"
                      :src="images"
                      :alt="name"
                      class="shrink-0 <lg:w-full"
                    />
                    <div class="text-sm">
                      <p class="line-clamp-2 mb-2 fw-medium ">
                        {{ name }}
                      </p>
                      <product-pricing :price :currency />
                    </div>
                    <base-link
                      :to="{ path: url, state: { suggestedPhrase: searchValue } }"
                      :aria-label="name"
                      class="absolute-0"
                      @click="reset"
                    />
                  </li>
                </ul>
              </div>
              <div v-if="showSuggestions" class="space-y-4" :class="dialog ? 'order-first' : '<md:order-first <md:px-4'">
                <h2 :class="brandClasses.suggestions.title">
                  {{ $t.topSearches }}
                </h2>
                <ul class="text-sm space-y-2 ">
                  <li v-for="{ label, highlightedLabel } in suggestions?.terms" :key="label">
                    <base-link
                      :to="{
                        path: `/search`,
                        query: { q: label },
                        state: { suggestedPhrase: label },
                      }"
                                            :aria-label="label"
                      @click="reset"
                    >
                      <span v-html="highlightedLabel" />
                    </base-link>
                  </li>
                </ul>
              </div>
              <div v-if="dialog" class="lg:order-first space-y-4">
                <h2 :class="brandClasses.suggestions.title">
                  {{ $t.helpfulLinks }}
                </h2>
                <vf-button v-if="dialog && $feature.enableChat" size="tiny" class="pl-3" @click="$chat.open()">
                  <vf-icon name="chat" size="md" class="mr-2" />
                  {{ $t.chatWithUs }}
                </vf-button>
                <vf-utility-navigation v-if="links" class="f-col items-start gap-2" :links />
              </div>
            </section>
          </div>
        </transition>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { autoUpdate, shift, useFloating } from '@floating-ui/vue'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import type { BaseInput as BaseInputType } from '#components'
import type { AutoSuggestData } from '#root/api/clients/product/data-contracts'
import { HeaderContextKey } from '#commerce/components/vf/header/context'

const props = defineProps<{
  dialog?: boolean
  transparent?: boolean
  focusTrapTarget?: HTMLElement | null
}>()

const emit = defineEmits<{
  /**
   * Emits when search is clicked
   */
  'search': [query: string]
  /**
   * Emits when image-search is clicked
   */
  'image-search': []
  /*
  * Emits when suggestions appear
  */
  'suggestions-shown': []
  'suggestions-hidden': []
}>()

const opened = defineModel('opened', { default: false })
const { links } = inject(HeaderContextKey)!

const {
  brandClasses,
  iconSize,
  lockBodyScroll,
  maxTopSearches = 7,
  mobileNavIconSize,
  searchThreshold = 0,
  showImageSearch
} = useAppConfig().components.vf.search
const { lock, unlock } = useBodyScroll()
const header = useHeaderStore()
const { hook, $feature, $viewport } = useNuxtApp()
const router = useRouter()
const { init: initSyte, ready: syteReady } = useSyte()

const wrapperRef = ref<HTMLDivElement>()
const formRef = ref<HTMLFormElement>()
const inputRef = ref<InstanceType<typeof BaseInputType>>()
const suggestionsRef = ref<HTMLDivElement>()

const searchValue = ref('')
const showMobileForm = ref(false)
const showSuggestions = ref(false)
const suggestionsQuery = reactive({ q: searchValue.value })
let abortSuggestions = false

const clear = (resetFocus = false) => {
  searchValue.value = ''
  if (resetFocus) inputRef.value?.$el.focus()
}

const close = () => {
  showMobileForm.value = false
  showSuggestions.value = false
}

const loadSyteOnMobile = () => {
  if ($feature.showShopSimilarOnSearch) initSyte()
}

const toggleSearch = () => {
  showMobileForm.value = !showMobileForm.value
  if (showMobileForm.value) loadSyteOnMobile()
}

const reset = () => {
  close()
  clear()
}

// @ts-expect-error Incorrect types
const { activate, deactivate } = useFocusTrap(() => props.focusTrapTarget || wrapperRef.value, {
  clickOutsideDeactivates: true,
  escapeDeactivates: false,
  onDeactivate: close,
  immediate: props.dialog
})

const { x, y, strategy } = useFloating(formRef, suggestionsRef, {
  placement: 'bottom-end',
  whileElementsMounted: autoUpdate,
  middleware: [shift()]
})

const suggestionsAvailable = (data: AutoSuggestData) =>
  data && (!!data.products?.length || !!data.terms?.filter(({ label }) => label !== suggestionsQuery.q).length)

const { data: suggestions, pending: suggestionsPending } = useApi().products.autoSuggest(suggestionsQuery, {
  immediate: false,
  server: false,
  transform({ products, terms }) {
    const termRegExp = new RegExp(`(${searchValue.value})`, 'gi')
    return {
      products: products?.slice(0, 4),
      terms: terms?.filter(({ label }) => label).slice(0, Math.min(maxTopSearches, 7)).map(({ label }) => ({
        label,
        highlightedLabel: label?.replace(termRegExp, '<span class="fw-medium">$1</span>')
      }))
    }
  },
  onResponse({ response: { _data } }) {
    showSuggestions.value = suggestionsAvailable(_data) && !abortSuggestions
  }
})

const handleOpenSuggestions = () => {
  if (searchValue.value.length >= searchThreshold && suggestionsAvailable(suggestions.value!))
    showSuggestions.value = true
}

const submit = () => {
  if (searchValue.value) {
    abortSuggestions = suggestionsPending.value
    emit('search', searchValue.value)
    router.push(useLocalisedRoute(`/search?q=${searchValue.value}`))
    reset()
  }
}

const onInput = useDebounceFn(() => {
  // hide suggestions if input length is below a threshold
  if (searchValue.value.length < searchThreshold) {
    showSuggestions.value = false
    return
  }

  // show previously fetched suggestions if the same search is re-entered
  if (searchValue.value === suggestionsQuery.q && !!suggestions) {
    showSuggestions.value = true
    return
  }
  // reset abort boolean when making a new search
  abortSuggestions = false
  suggestionsQuery.q = searchValue.value

  if (!searchValue.value && $viewport.md) close()
}, 100)

watch([showMobileForm, showSuggestions], () => {
  if (showMobileForm.value || showSuggestions.value) {
    activate()
    opened.value = true
    if (lockBodyScroll) lock()
    handleOpenSuggestions()
  }
  else {
    deactivate()
    opened.value = false
    if (lockBodyScroll) unlock()
  }
})

watch(searchValue, onInput)

// close suggestions and clear search if route changes
router.beforeEach(reset)
</script>
